import React, { useState } from 'react';

const IframeViewer = ({ url, buttonText = "Database" }) => {
    const [showIframe, setShowIframe] = useState(false);

    const handleClose = () => {
        setShowIframe(false);
    };

    if (!url) return <span>N/A</span>;

    return (
        <>
            <button
                onClick={() => setShowIframe(true)}
                className="action-link"
                style={{
                    background: 'none',
                    border: 'none',
                    color: '#0d6efd', // Bootstrap primary blue
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    padding: '0.25rem 0.5rem',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    display: 'inline-block',
                }}
            >
                {buttonText}
            </button>


            {showIframe && (
                <div className="modal-backdrop"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1050
                    }}
                >
                    <div className="modal-content"
                        style={{
                            background: 'white',
                            borderRadius: '8px',
                            width: '95vw',
                            height: '90vh',
                            position: 'relative'
                        }}
                    >
                        <div className="modal-header"
                            style={{
                                padding: '1rem',
                                borderBottom: '1px solid #dee2e6',
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'center'
                            }}
                        >
                            <div>
                                <button
                                    onClick={handleClose}
                                    className="btn btn-primary"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                        <div className="modal-body" style={{ height: 'calc(90vh - 76px)' }}>
                            <iframe
                                src={url}
                                title="Content Preview"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    border: 'none'
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default IframeViewer;