import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackToAdminDashboard from "./BackToAdminDashboard";

const EditAboutList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/about-service/${id}`)
      .then((res) => res.json())
      .then((info) => setSliders(info));
  }, [id]);

  const handleSlider = (event) => {
    event.preventDefault();
    const titleTop = event.target.titleTop.value;
    const title = event.target.title.value;
    const description = event.target.description.value;
    const imgLeft = event.target.imgLeft.value;
    const imgRight = event.target.imgRight.value;
    const pointOne = event.target.pointOne.value;
    const pointTwo = event.target.pointTwo.value;
    const pointThree = event.target.pointThree.value;
    const btnText = event.target.btnText.value;
    const btnUrl = event.target.btnUrl.value;

    const footerSocial = {
      titleTop,
      title,
      description,
      imgLeft,
      imgRight,
      pointOne,
      pointTwo,
      pointThree,
      btnText,
      btnUrl
    };

    const url = `https://leadisking-14a2689aac56.herokuapp.com/edit-about-service/${id}`;
    fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(footerSocial),
    })
      .then((res) => res.json())
      .then((result) => {
        navigate("/admin/about-service-list/");
      });
  }

  return (
    <div
      className="container-fluid py-5"
      style={{
        backgroundColor: '#f8f9fa',
        minHeight: '100vh'
      }}
    >
      <BackToAdminDashboard />
      <div
        className="row justify-content-center mt-5"
        style={{
          maxWidth: '800px',
          margin: '0 auto',
          backgroundColor: 'white',
          borderRadius: '12px',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          padding: '2rem'
        }}
      >
        <div className="col-12">
          <h4
            className="text-center mb-4"
            style={{
              color: '#333',
              fontWeight: '600',
              borderBottom: '2px solid #007bff',
              paddingBottom: '0.5rem'
            }}
          >
            Update About Section
          </h4>
          <form onSubmit={handleSlider}>
            <div className="row g-3">
              <div className="col-md-6">
                <label htmlFor="title" className="form-label">Title Top</label>
                <input
                  type="text"
                  id="titleTop"
                  className="form-control"
                  placeholder="Enter Title Top"
                  name="titleTop"
                  defaultValue={sliders.titleTop}
                  style={{
                    borderColor: '#007bff',
                    boxShadow: 'none'
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="title" className="form-label">Title</label>
                <input
                  type="text"
                  id="title"
                  className="form-control"
                  placeholder="Enter Title"
                  name="title"
                  defaultValue={sliders.title}
                  style={{
                    borderColor: '#007bff',
                    boxShadow: 'none'
                  }}
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="img" className="form-label">Image URL Left</label>
                <input
                  type="text"
                  id="imgLeft"
                  className="form-control"
                  placeholder="Enter Image URL"
                  name="imgLeft"
                  defaultValue={sliders.imgLeft}
                  style={{
                    borderColor: '#007bff',
                    boxShadow: 'none'
                  }}
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="img" className="form-label">Image URL Right</label>
                <input
                  type="text"
                  id="imgRight"
                  className="form-control"
                  placeholder="Enter Image URL"
                  name="imgRight"
                  defaultValue={sliders.imgRight}
                  style={{
                    borderColor: '#007bff',
                    boxShadow: 'none'
                  }}
                />
              </div>
              <div className="col-12">
                <label htmlFor="description" className="form-label">Description</label>
                <textarea
                  id="description"
                  className="form-control"
                  placeholder="Enter Description"
                  name="description"
                  defaultValue={sliders.description}
                  rows="4"
                  style={{
                    borderColor: '#007bff',
                    boxShadow: 'none',
                    resize: 'vertical'
                  }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="pointOne" className="form-label">Point One</label>
                <input
                  type="text"
                  id="pointOne"
                  className="form-control"
                  placeholder="Enter Point One"
                  name="pointOne"
                  defaultValue={sliders.pointOne}
                  style={{
                    borderColor: '#007bff',
                    boxShadow: 'none'
                  }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="pointTwo" className="form-label">Point Two</label>
                <input
                  type="text"
                  id="pointTwo"
                  className="form-control"
                  placeholder="Enter Point Two"
                  name="pointTwo"
                  defaultValue={sliders.pointTwo}
                  style={{
                    borderColor: '#007bff',
                    boxShadow: 'none'
                  }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="pointThree" className="form-label">Point Three</label>
                <input
                  type="text"
                  id="pointThree"
                  className="form-control"
                  placeholder="Enter Point Three"
                  name="pointThree"
                  defaultValue={sliders.pointThree}
                  style={{
                    borderColor: '#007bff',
                    boxShadow: 'none'
                  }}
                />
              </div>


              <div className="col-md-12">
                <label htmlFor="btnText" className="form-label">Button Text</label>
                <input
                  type="text"
                  id="btnText"
                  className="form-control"
                  placeholder="Enter Button Text"
                  name="btnText"
                  defaultValue={sliders.btnText}
                  style={{
                    borderColor: '#007bff',
                    boxShadow: 'none'
                  }}
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="btnUrl" className="form-label">Button Link</label>
                <input
                  type="text"
                  id="btnUrl"
                  className="form-control"
                  placeholder="Enter Button Link"
                  name="btnUrl"
                  defaultValue={sliders.btnUrl}
                  style={{
                    borderColor: '#007bff',
                    boxShadow: 'none'
                  }}
                />
              </div>
              <div className="col-12 text-center mt-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg rounded-pill px-5"
                  style={{
                    backgroundColor: '#007bff',
                    border: 'none',
                    transition: 'all 0.3s ease',
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                  }}
                  onMouseOver={(e) => {
                    e.target.style.transform = 'scale(1.05)';
                    e.target.style.backgroundColor = '#0056b3';
                  }}
                  onMouseOut={(e) => {
                    e.target.style.transform = 'scale(1)';
                    e.target.style.backgroundColor = '#007bff';
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAboutList;