
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Star } from "lucide-react";

const Testimonials = () => {
  const [title, setTitle] = useState({});
  const [testimonials, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Cache and fetch functions remain the same
  const generateRandomRating = () => {
    return (Math.random() * (5 - 4.5) + 4.5).toFixed(1);
  };

  const checkCacheValidity = (timestamp) => {
    const now = new Date().getTime();
    const threeHours = 3 * 60 * 60 * 1000;
    return now - timestamp < threeHours;
  };

  const fetchTestimonials = async () => {
    try {
      const cachedData = localStorage.getItem('testimonials-cache');
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        if (checkCacheValidity(timestamp)) {
          setTestimonials(data.map(item => ({
            ...item,
            rating: generateRandomRating()
          })));
          setIsLoading(false);
          return;
        }
      }

      const response = await fetch(`https://leadisking-14a2689aac56.herokuapp.com/testimonials/`);
      const data = await response.json();

      const testimonialsWithRatings = data.map(item => ({
        ...item,
        rating: generateRandomRating()
      }));

      localStorage.setItem('testimonials-cache', JSON.stringify({
        data: testimonialsWithRatings,
        timestamp: new Date().getTime()
      }));

      setTestimonials(testimonialsWithRatings);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
      setIsLoading(false);
    }
  };

  const fetchTitle = async () => {
    try {
      const cachedTitle = localStorage.getItem('testimonials-title-cache');
      if (cachedTitle) {
        const { data, timestamp } = JSON.parse(cachedTitle);
        if (checkCacheValidity(timestamp)) {
          setTitle(data);
          return;
        }
      }

      const response = await fetch(`https://leadisking-14a2689aac56.herokuapp.com/testimonials-title/`);
      const data = await response.json();

      localStorage.setItem('testimonials-title-cache', JSON.stringify({
        data: data[0],
        timestamp: new Date().getTime()
      }));

      setTitle(data[0]);
    } catch (error) {
      console.error('Error fetching title:', error);
    }
  };

  useEffect(() => {
    fetchTestimonials();
    fetchTitle();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div style={{
      background: 'linear-gradient(180deg, #ffffff 0%, #f8f9fa 100%)',
      padding: '5rem 0'
    }}>
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-lg-8 text-center">
            <h4 className="starta-heading mx-auto" style={{
              color: '#0d6efd',
              fontSize: '1.25rem',
              marginBottom: '1rem'
            }}>Customer Reviews</h4>
            <h2 style={{
              fontSize: '2.5rem',
              fontWeight: '700',
              marginBottom: '1.5rem'
            }}>What People Say About Us</h2>
            <div style={{
              width: '80px',
              height: '4px',
              background: '#0d6efd',
              margin: '0 auto',
              borderRadius: '2px'
            }}></div>
          </div>
        </div>

        {testimonials.length > 0 && (
          <Swiper
            modules={[Autoplay, Pagination, Navigation]}
            spaceBetween={30}
            centeredSlides={false}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }}
            style={{
              padding: '20px 10px 40px',
              width: '100%',
              height: '100%'
            }}
          >
            {testimonials.map((testimonial) => (
              <SwiperSlide key={testimonial._id}>
                <div style={{
                  background: '#fff',
                  borderRadius: '1rem',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  padding: '1.5rem',
                  transition: 'transform 0.3s ease',
                  height: '100%',
                  maxHeight: '800px',
                  overflow: 'auto'
                }} className="h-100">
                  <div className="d-flex align-items-center mb-4">
                    <div style={{ position: 'relative' }}>
                      <img
                        src={testimonial.personImg}
                        alt={testimonial.personName}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/api/placeholder/64/64";
                        }}
                        style={{
                          width: '64px',
                          height: '64px',
                          borderRadius: '50%',
                          objectFit: 'cover',
                          border: '2px solid #0d6efd'
                        }}
                      />
                      <div style={{
                        position: 'absolute',
                        bottom: '-8px',
                        right: '-8px',
                        background: '#0d6efd',
                        borderRadius: '50%',
                        padding: '4px'
                      }}>
                        <Star className="text-white" size={16} />
                      </div>
                    </div>
                    <div className="ms-3">
                      <h6 style={{
                        fontSize: '1.1rem',
                        fontWeight: '600',
                        marginBottom: '0.25rem'
                      }}>{testimonial.personName}</h6>
                      <span style={{
                        fontSize: '0.875rem',
                        color: '#6c757d'
                      }}>{testimonial.personTitle}</span>
                    </div>
                  </div>
                  <p style={{
                    color: '#495057',
                    lineHeight: '1.6',
                    marginBottom: '1rem',
                    display: '-webkit-box',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>{testimonial.desc}</p>
                  <div className="d-flex align-items-center">
                    <Star className="text-warning" size={20} />
                    <span style={{
                      marginLeft: '0.5rem',
                      fontWeight: '600'
                    }}>{testimonial.rating}</span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default Testimonials;