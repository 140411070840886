import React from 'react';
import About from './About';
import BestOption from './BestOption';

const AboutPage = () => {
    return (
        <>
            <About />
            <BestOption />

        </>
    );
};

export default AboutPage;