import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const [logo, setLogo] = useState({});
  const [footer, setFooter] = useState({});
  const [social, setSocial] = useState({});
  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info[0]));
  }, []);
  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/footer-links`)
      .then((res) => res.json())
      .then((info) => setFooter(info[0]));
  }, []);
  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setSocial(info[0]));
  }, []);

  const [contact, setContact] = useState({});

  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/contact/`)
      .then((res) => res.json())
      .then((info) => setContact(info[0]));
  }, []);

  return (
    <>

      <footer className="starta-footer pt-5">
        <div className="container py-5">
          <div className="row">
            {/* block 1 */}
            <div className="col-md-6 border-end">
              <div className="mb-4">
                <img className="logo" src={logo.logo} alt="Logo" />
              </div>
              <p className="starta-desc">
                {footer.FooterAbout}
              </p>

            </div>
            <div className="col-md-3 lap-sm-50 tab-100 mt-sm-4">
              <div className="row mx-auto">
                {/* block 2 */}
                <div className="col-md-12 col-sm-12">
                  <h4 className="starta-h4 mb-4">Services</h4>

                  <ul className="footer-list list-unstyled">

                    <li className="menu-animation">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="menu-animation">
                      <Link to="/pricing">Pricing</Link>
                    </li>
           
                    <li className="menu-animation">
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li className="menu-animation">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li className="menu-animation">
                      <Link to="/terms-condition">Terms</Link>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <div className="col-md-3 lap-sm-50 tab-100 mt-sm-4">
              <div className="row mx-auto">
                {/* block 2 */}
                <div className="col-md-12 col-sm-12">
                  <h4 className="starta-h4 mb-4">Social</h4>
                  <p className="starta-desc">
                    Connect With Us On Social Media. Follow us on your favorite social platforms to be part of our growing family.
                  </p>
                  <ul className="starta-social list-unstyled">
                    <li>
                      <a href="https://www.youtube.com/@LeadIsKing">
                        <i className="fa-brands fa-youtube" />
                      </a>
                    </li>
                                      
                    <li>
                      <a href="https://www.facebook.com/LeadIsKing">
                        <i className="fa-brands fa-square-facebook" />
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* copyright */}
        <div className="copyright pt-5 pb-5">{footer.CopyRight}</div>
      </footer>

    </>
  );
};

export default Footer;
