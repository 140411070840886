import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import auth from "../../firebase.init";
import axios from "axios";
import BackToAdminDashboard from "./BackToAdminDashboard";

const EditPackage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [p, setPackage] = useState([]);
  const [user] = useAuthState(auth);
  const [imgUrl, setImgUrl] = useState(p.img || "");
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/package/${id}`)
      .then((res) => res.json())
      .then((info) => setPackage(info));
    setImgUrl(p.img || "");
  }, []);

  const handleImageUpload = async (event) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);

    try {
      const response = await axios.post(
        "https://api.imgbb.com/1/upload?key=9b9a8d0dcddf9fdbc0d69db3ef376eac",
        formData
      );
      setImgUrl(response.data.data.url);
    } catch (error) {
      console.error("Image upload failed: ", error);
    }
  };

  const handlePackages = (event) => {
    event.preventDefault();
    const formData = {
      packageName: event.target.packageName.value,
      price: event.target.price.value,
      accessLink1: event.target.accessLink1.value,
      accessLink2: event.target.accessLink2.value,
      accessLink3: event.target.accessLink3.value,
      img: imgUrl,
      pointOne: event.target.pointOne.value,
      pointTwo: event.target.pointTwo.value,
      pointThree: event.target.pointThree.value,
      pointFour: event.target.pointFour.value,
      pointFive: event.target.pointFive.value,
      pointSix: event.target.pointSix.value,
      pointSeven: event.target.pointSeven.value,
      pointEight: event.target.pointEight.value,
      pointNine: event.target.pointNine.value,
      pointTen: event.target.pointTen.value,
    };

    fetch(`https://leadisking-14a2689aac56.herokuapp.com/edit-package/${id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then(() => {
        navigate("/admin/packages/");
      });
  };

  const renderFormInput = (label, name, defaultValue, type = "text", colClass = "col-md-6") => (
    <div className={`${colClass} mb-4`}>
      <div className="form-group">
        <label className="form-label fw-semibold text-secondary mb-2">
          {label}
        </label>
        <input
          type={type}
          className="form-control form-control-lg shadow-sm border-0"
          placeholder={`Enter ${label}`}
          name={name}
          defaultValue={defaultValue}
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "10px",
            padding: "12px 15px"
          }}
        />
      </div>
    </div>
  );

  return (
    <div className="py-5" style={{ backgroundColor: "#ffffff" }}>
      <BackToAdminDashboard />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="card border-0 shadow-lg" style={{ borderRadius: "15px" }}>
              <div className="card-body p-5">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h2 className="card-title mb-0" style={{ color: "#2c3e50", fontWeight: "600" }}>
                    Edit {p.packageName} Package
                  </h2>

                </div>

                <form onSubmit={handlePackages}>
                  <div className="row">
                    {renderFormInput("Package Name", "packageName", p.packageName)}
                    {renderFormInput("Package Price", "price", p.price)}
                    {renderFormInput("Access Link One", "accessLink1", p.accessLink1, "text", "col-12")}
                    {renderFormInput("Access Link Two", "accessLink2", p.accessLink2, "text", "col-12")}
                    {renderFormInput("Access Link Three", "accessLink3", p.accessLink3, "text", "col-12")}

                    <div className="col-12">
                      <h4 className="mb-4 mt-2" style={{ color: "#2c3e50" }}>Package Features</h4>
                    </div>

                    {[...Array(10)].map((_, index) => (
                      renderFormInput(
                        `Feature ${index + 1}`,
                        `point${['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'][index]}`,
                        p[`point${['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'][index]}`]
                      )
                    ))}

                    <div className="col-12 mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg px-5 py-3"
                        style={{
                          backgroundColor: "#3498db",
                          border: "none",
                          borderRadius: "10px",
                          boxShadow: "0 4px 6px rgba(52, 152, 219, 0.2)",
                          transition: "all 0.3s ease"
                        }}
                      >
                        Update Package
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPackage;