import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Plans = () => {
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [title, setTitle] = useState({});

  useEffect(() => {
    fetch("https://leadisking-14a2689aac56.herokuapp.com/packages")
      .then((res) => res.json())
      .then((info) => setPackages(info));
  }, []);

  useEffect(() => {
    fetch("https://leadisking-14a2689aac56.herokuapp.com/package-titles/")
      .then((res) => res.json())
      .then((info) => setTitle(info[0]));
  }, []);

  const getFeaturesList = (pkg) => [
    pkg.pointOne,
    pkg.pointTwo,
    pkg.pointThree,
    pkg.pointFour,
    pkg.pointFive,
    pkg.pointSix,
    pkg.pointSeven,
    pkg.pointEight,
    pkg.pointNine,
    pkg.pointTen,
  ];

  const handlePackageClick = (packageId) => {
    localStorage.setItem("lastClickedPackageId", packageId);
  };
  return (
    <section className="starta-plans" id="plan">
      <div className="container">
        <h1 className="starta-heading mx-auto">{title.titleOne}</h1>
        <h1 className="starta-h1 text-center">{title.titleOne}</h1>
        <div className="row justify-content-center">
          {packages.map((pkg, index) => (
            <div key={pkg._id} className="col-md-4 lap-sm-50 tab-100">
              <div className={`plan ${index === 1 ? "best" : ""}`}>
                <div className="row align-items-center">
                  <div className="col-md-5 col-sm-3 col-5">
                    <i className="starta-icon featureIcon fa-regular fa-lightbulb" />
                  </div>
                  <div className="col-md-7 p-md-0 col-sm-9 col-7">
                    <article className="plan-heading">
                      <h3 className="starta-h2">{pkg.packageName}</h3>
                      <p className="starta-h2">
                        <span>$</span>
                        {pkg.price}
                      </p>
                    </article>
                  </div>

                  <ul className="starta-list list-unstyled">
                    {getFeaturesList(pkg).map((feature, idx) => (
                      <li key={idx}>
                        <i className="fa-solid fa-circle-check" />
                        {feature}
                      </li>
                    ))}
                  </ul>

                  <button className="starta-button">
                    <Link
                      to={`/details/${pkg._id}`}
                      className="position-relative"
                      onClick={() => handlePackageClick(pkg._id)}
                    >
                      <span>$</span>
                      {pkg.price} Buy Now
                    </Link>
                    {index === 1 && <span className="starta-button-hover" />}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Plans;
