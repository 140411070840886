import React, { useEffect, useState } from 'react';

const About = () => {
    const [abouts, setAbouts] = useState([]);

    useEffect(() => {
        fetch(`https://leadisking-14a2689aac56.herokuapp.com/about-services`)
            .then((res) => res.json())
            .then((info) => setAbouts(info));
    }, []);

    return (
        <>
            <section className="starta-project my-5 py-md-5" id='features'>
                <div className="container">

                    {
                        abouts.map(e => <>
                            <div className="row mt-5 mb-5">
                                <div className="col-md-6 pe-md-5">
                                    <div className="mb-4">
                                        <img
                                            className="starta-block-img"
                                            src={e.imgLeft}
                                            alt="BG"
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="starta-list list-unstyled">
                                                <li className="mb-3">
                                                    <i className="fa-solid fa-circle-check" />
                                                    {e.pointOne}
                                                </li>
                                                <li className="mb-3">
                                                    <i className="fa-solid fa-circle-check" />
                                                    {e.pointTwo}
                                                </li>
                                                <li className="mb-3">
                                                    <i className="fa-solid fa-circle-check" />
                                                    {e.pointThree}
                                                </li>

                                            </ul>
                                        </div>

                                    </div>
                                    <div className="d-flex align-items-center justify-space-between flex-wrap mt-5">
                                        <button onClick={() => (window.location.href = e.btnUrl)} className="starta-button starta-button-2">
                                            <span className="position-relative">{e.btnText}</span>
                                            <span className="starta-button-hover" />
                                        </button>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <h2 className="starta-heading">{e.titleTop}</h2>
                                    <h1 className="starta-h2">
                                        {e.title}
                                    </h1>
                                    <p className="starta-desc">
                                        {e.description}
                                    </p>
                                    <img
                                        className="starta-block-img mt-3 animate-img3"
                                        src={e.imgRight}
                                        alt="Block Img"
                                    />
                                </div>
                            </div>
                        </>)
                    }

<div className="database-animation d-flex justify-content-center container"><img src="https://attherate.dev/leadisking/LeadisKing.gif" alt="" /></div>

                </div>
            </section>


        </>
    );
};

export default About;