import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import auth from "../../firebase.init";
import { useAuthState } from "react-firebase-hooks/auth";
import { HandCoins, LayoutDashboard, UserPen, UserRoundSearch } from "lucide-react";
import { signOut } from "firebase/auth";
import CustomLink from "./CustomLink";

const NavBar = () => {
  const [user] = useAuthState(auth);
  const [admin, setAdmin] = useState([]);
  const [logo, setLogo] = useState({});
  const [banner, setBanner] = useState({});
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/users`)
      .then((res) => res.json())
      .then((info) => setAdmin(info));
  }, []);
  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info[0]));
  }, []);
  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/banner/`)
      .then((res) => res.json())
      .then((info) => setBanner(info[0]));
  }, []);

  const [profile, setProfile] = useState([]);
  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/profiles/`)
      .then((res) => res.json())
      .then((info) => setProfile(info));
  }, []);

  const isAdmin = user && admin.some((adm) => adm.userEmail === user.email);
  const isHomePage = location.pathname === "/";

  const toggleMobileMenu = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    if (e.currentTarget.classList.contains('close')) {
      setIsMobileMenuOpen(false);
    } else {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    }
  };

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const mobileNav = document.querySelector('.starta-mobile-nav');
      const menuIcon = document.querySelector('.menuIcon');

      if (isMobileMenuOpen && mobileNav && !mobileNav.contains(event.target) && !menuIcon.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMobileMenuOpen]);

  // Close mobile menu when route changes
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  const handleSignout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Sign-out error:", error);
      });
  };

  // Function to extract YouTube video ID from URL
  const getYouTubeVideoId = (url) => {
    if (!url) return '';
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : '';
  };

  // Get video ID from banner data
  const videoId = banner?.youtubeLink ? getYouTubeVideoId(banner.youtubeLink) : '';


  const isCacheValid = (timestamp) => {
    const TWELVE_HOURS = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
    const now = new Date().getTime();
    return now - timestamp < TWELVE_HOURS;
  };

  // Function to fetch banner data
  const fetchBannerData = async () => {
    try {
      const response = await fetch(`https://leadisking-14a2689aac56.herokuapp.com/banner/`);
      const data = await response.json();
      const bannerData = {
        data: data[0],
        timestamp: new Date().getTime()
      };
      localStorage.setItem('bannerData', JSON.stringify(bannerData));
      setBanner(data[0]);
    } catch (error) {
      console.error('Error fetching banner data:', error);
    }
  };

  useEffect(() => {
    const cachedBanner = localStorage.getItem('bannerData');
    
    if (cachedBanner) {
      const { data, timestamp } = JSON.parse(cachedBanner);
      
      if (isCacheValid(timestamp)) {
        // Use cached data if it's still valid
        setBanner(data);
      } else {
        // Fetch new data if cache has expired
        fetchBannerData();
      }
    } else {
      // Fetch new data if no cache exists
      fetchBannerData();
    }
  }, []);
  return (
    <>
      <header id="top" className="d-flex flex-column">
        {/* Desktop Menu */}
        <nav className="starta-nav">
          <div className="container d-flex justify-content-between align-items-center">
            <Link to="/">
              <div className="logo">
                <img src={logo.logo} />

              </div>
            </Link>
            <ul className="list-unstyled-menu starta-menu">
              <li>
                <a className="menu-animation" href="/">Home</a>
              </li>

              <li>
                <Link className="menu-animation" to="/pricing">Pricing</Link>
              </li>
            
              <li>
                <Link className="menu-animation" to="/contact">Contact</Link>
              </li>
            </ul>
            <a href="#" className="starta-helpline">
              <div className="d-flex">
                {user ? (
                  <>
                    {profile.map((pro) =>
                      pro.userEmail === user?.email ? (
                        <div className="nav-item dropdown" key={pro._id}>
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={
                                pro.profileImg ||
                                "https://st3.depositphotos.com/15648834/17930/v/450/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                              }
                              alt="Profile"
                              className="rounded-circle me-2"
                              style={{ width: "40px", height: "40px" }}
                            />
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end p-3">
                            <li>
                              <Link className="dropdown-item" to="/user-dashboard">
                                <LayoutDashboard /> Dashboard
                              </Link>
                            </li>
                           
                           
                            <li>
                              <Link className="dropdown-item" to={`/update-profile/${pro._id}`}>
                                <UserPen /> Edit Profile
                              </Link>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <CustomLink onClick={handleSignout} variant="danger" size="sm">Logout</CustomLink>
                            </li>
                          </ul>
                        </div>
                      ) : null
                    )}
                    {isAdmin && (
                      <>
                        <CustomLink to="/admin/dashboard">Admin</CustomLink>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <CustomLink to="/login">Login</CustomLink>

                  </>
                )}
              </div>
            </a>
          </div>
        </nav>

        {/* Mobile Menu Icon */}
        <button
          className={`menuIcon ${isMobileMenuOpen ? 'active' : ''}`}
          onClick={toggleMobileMenu}
          aria-label="Toggle mobile menu"
        >
          <span />
          <span />
          <span />
        </button>

        {/* Mobile Menu */}
        <nav className={`starta-mobile-nav ${isMobileMenuOpen ? 'active' : ''}`}>
          <ul className="list-unstyled starta-menu">
            <li>
              <a
                className="menu-animation active"
                href="/"
              >
                Home
              </a>
            </li>
            <li>
              <a className="menu-animation" href="/pricing">Pricing</a>
            </li>
            <li>
              <a className="menu-animation" href="/about">About</a>
            </li>
            <li>
              <a className="menu-animation" href="/why-choose">Why Choose</a>
            </li>
            <li>
              <a className="menu-animation" href="/contact">Contact</a>
            </li>

            {isAdmin && (
              <li><a href="/admin/dashboard" className="menu-animation">Admin</a></li>
            )}
            {user && (
              <li><a href="/user-dashboard" className="menu-animation">Dashboard</a></li>
            )}
          </ul>
        </nav>

        {isHomePage && (
          <div className="container d-flex flex-column flex-grow-1 position-relative z-index-3">
            <section className="starta-hero my-5 d-flex flex-column justify-content-center flex-grow-1">
              <div className="row mt-5 pt-4">
                <div className="col-md-6 order_c_2">
                  <h1 className="heroHeading">
                    {banner.bannerHeadingText1}
                  </h1>
                  <p className="heroDesc">
                    {banner.bannerText}
                  </p>
                  <button onClick={() => (window.location.href = banner.btnLink)} className="starta-button">
                    <span className="position-relative">{banner.btnText}</span>
                    <span className="starta-button-hover" />
                  </button>
                </div>

                <div className="col-md-6 position-relative">
                  <div className="home-1_hero-section d-flex justify-content-center container" id="hero">
                    <div className="video-wrapper" style={{
                      position: "relative",
                      width: "1000px", 
                      height: "450px",
                      borderRadius: "1rem",
                      overflow: "hidden"
                    }}>
                      <iframe
                        src={`https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          border: "0",
                          objectFit: "cover"
                        }}
                      ></iframe>
                    </div>
                  </div>
                </div>


              </div>
            </section>
          </div>
        )}

        {/* Hero Background */}
        <div className="heroBG">
          <img src="/assets/images/hero/bg.jpg" alt="Background" />
        </div>
      </header>
    </>
  );
};

export default NavBar;