import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../firebase.init";

const ReceivedPayment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [order, setOrder] = useState({});

  useEffect(() => {
    const fetchDataAndUpdatePayment = async () => {
      try {
        console.log("Fetching order data...");
        const orderResponse = await fetch(`https://leadisking-14a2689aac56.herokuapp.com/order/${id}`);
        
        if (!orderResponse.ok) {
          console.error(`Order fetch failed with status ${orderResponse.status}`);
          return;
        }
        
        const orderData = await orderResponse.json();
        setOrder(orderData);
        console.log("Order data fetched:", orderData);

        if (orderData.paymentStatus !== "Received") {
          console.log("Updating payment status...");
          const paymentResponse = await fetch(
            `https://leadisking-14a2689aac56.herokuapp.com/payment-received/${order._id}`,
            {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ paymentStatus: "Received" }),
            }
          );

          if (!paymentResponse.ok) {
            console.error(
              `Payment update failed with status ${paymentResponse.status}`
            );
            return;
          }

          console.log("Payment status updated successfully.");
          navigate("/user-dashboard/my-orders/");
        }
      } catch (error) {
        console.error("Error fetching and updating payment data:", error);
      }
    };

    fetchDataAndUpdatePayment();
  }, [id, navigate]);

  return (
    <section className="py-5">
      <div className="container">
        <div className="row vh-100 justify-content-center align-items-center">
          <div className="col-lg-8">
            <div
              className="card p-4 shadow-sm border-0"
              style={{ backgroundColor: "rgb(250, 249, 245)" }}
            >
              <h2 className="text-center mb-4">Payment Confirmation</h2>
              <div id="receivedPaymentForm">
                <div className="text-center">
                  {order.paymentStatus === "Received" ? (
                    <div>
                      <p className="text-success">Payment has already been received.</p>
                    </div>
                  ) : (
                    <p className="text-primary">
                      Updating payment status. Please wait...
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReceivedPayment;
