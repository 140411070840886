import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div
      className="vh-100 d-flex align-items-center justify-content-center"
      style={{
        background: "linear-gradient(to right bottom, #f8f9fa, #e9ecef)"
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10 text-center">
            <div
              className="error-content p-4"
              style={{
                borderRadius: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.95)",
                boxShadow: "0 10px 30px rgba(0, 0, 0, 0.05)"
              }}
            >
              <div 
                className="error-image mb-5"
                style={{
                  maxWidth: "400px",
                  margin: "0 auto",
                  transition: "transform 0.3s ease"
                }}
              >
                <img
                  src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?t=st=1734845599~exp=1734849199~hmac=015af8be8f9ad9f17f31bdab33bb6543ba9ae868581bd7d2100ba57225235e24&w=740"
                  alt="404 Error"
                  className="img-fluid"
                  style={{
                    objectFit: "contain",
                    maxHeight: "300px"
                  }}
                />
              </div>
              
              <h2
                className="mb-4"
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "700",
                  color: "#212529"
                }}
              >
                Page Not Found
              </h2>
              
              <p
                className="mb-5"
                style={{
                  fontSize: "1.1rem",
                  color: "#6c757d",
                  maxWidth: "500px",
                  margin: "0 auto"
                }}
              >
                The requested URL you are looking for doesn't exist on this server.
              </p>
              
              <Link
                to="/"
                className="btn btn-primary btn-lg"
                style={{
                  borderRadius: "50px",
                  padding: "12px 32px",
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  textTransform: "none",
                  transition: "all 0.3s ease",
                  backgroundColor: "#0d6efd",
                  border: "none",
                  boxShadow: "0 4px 15px rgba(13, 110, 253, 0.2)"
                }}
                onMouseOver={(e) => {
                  e.target.style.transform = "translateY(-2px)";
                  e.target.style.boxShadow = "0 6px 20px rgba(13, 110, 253, 0.3)";
                }}
                onMouseOut={(e) => {
                  e.target.style.transform = "translateY(0)";
                  e.target.style.boxShadow = "0 4px 15px rgba(13, 110, 253, 0.2)";
                }}
              >
                Back to Homepage
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;