import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackToAdminDashboard from "./BackToAdminDashboard";

const EditRoadMaps = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [road, SetRoad] = useState([]);

  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/road/`)
      .then((res) => res.json())
      .then((info) => SetRoad(info));
  }, []);

  const handleWhyEdit = (event) => {
    event.preventDefault();

    const updateRoad = {
      // Top Section
      title: event.target.title.value,
      pointOne: event.target.pointOne.value,
      pointTwo: event.target.pointTwo.value,
      btnText: event.target.btnText.value,
      btnUrl: event.target.btnUrl.value,
      bgimage: event.target.bgimage.value,
      
      // Bottom Section
      titleTop: event.target.titleTop.value,
      titleTwo: event.target.titleTwo.value,
      desc: event.target.desc.value,
      image: event.target.image.value,
      pointTitle: event.target.pointTitle.value,
      sectionPointOne: event.target.sectionPointOne.value,
      sectionPointTwo: event.target.sectionPointTwo.value,
      sectionPointThree: event.target.sectionPointThree.value,
      sectionPointFour: event.target.sectionPointFour.value,
      emailDescText: event.target.emailDescText.value,
      email: event.target.email.value,
    };

    const url = `https://leadisking-14a2689aac56.herokuapp.com/edit-road/${id}`;
    fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(updateRoad),
    })
      .then((res) => res.json())
      .then((result) => {
        navigate("/admin/setting-homepage/");
      });
  };

  const formContainerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '2rem',
    backgroundColor: '#f8f9fa',
    borderRadius: '12px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  };

  const inputStyle = {
    borderRadius: '8px',
    padding: '0.75rem',
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    transition: 'all 0.3s ease',
  };

  const labelStyle = {
    fontWeight: '600',
    marginBottom: '0.5rem',
    color: '#495057',
  };

  const buttonStyle = {
    backgroundColor: '#007bff',
    color: 'white',
    borderRadius: '50px',
    padding: '0.75rem 1.5rem',
    border: 'none',
    fontWeight: '600',
    transition: 'all 0.3s ease',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  };

  const sectionTitleStyle = {
    borderBottom: '2px solid #007bff',
    paddingBottom: '0.5rem',
    marginBottom: '1.5rem',
    color: '#2c3e50',
  };

  return (
    <div className="container-fluid py-4" style={{ backgroundColor: '#f4f6f9' }}>
      <BackToAdminDashboard />
      <form 
        onSubmit={handleWhyEdit} 
        style={formContainerStyle}
        className="needs-validation mt-5 mb-5"
        noValidate
      >
        <h2 className="text-center mb-4 text-dark">Edit Page Content</h2>
        
        {road.map((e, index) => (
          <div key={index} className="row g-3">
            {/* Top Section */}
            <div className="col-12">
              <h3 style={sectionTitleStyle}>Top Section</h3>
            </div>
            <div className="col-md-12 mb-3">
              <label htmlFor="title" className="form-label" style={labelStyle}>Title</label>
              <input type="text" className="form-control" id="title" name="title" defaultValue={e.title} style={inputStyle} required />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="pointOne" className="form-label" style={labelStyle}>Point One</label>
              <input type="text" className="form-control" id="pointOne" name="pointOne" defaultValue={e.pointOne} style={inputStyle} required />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="pointTwo" className="form-label" style={labelStyle}>Point Two</label>
              <input type="text" className="form-control" id="pointTwo" name="pointTwo" defaultValue={e.pointTwo} style={inputStyle} required />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="btnText" className="form-label" style={labelStyle}>Button Text</label>
              <input type="text" className="form-control" id="btnText" name="btnText" defaultValue={e.btnText} style={inputStyle} required />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="btnUrl" className="form-label" style={labelStyle}>Button URL</label>
              <input type="text" className="form-control" id="btnUrl" name="btnUrl" defaultValue={e.btnUrl} style={inputStyle} required />
            </div>
            <div className="col-md-12 mb-3">
              <label htmlFor="bgimage" className="form-label" style={labelStyle}>Background Image URL</label>
              <input type="text" className="form-control" id="bgimage" name="bgimage" defaultValue={e.bgimage} style={inputStyle} required />
            </div>

            {/* Bottom Section */}
            <div className="col-12">
              <h3 style={sectionTitleStyle}>Bottom Section</h3>
            </div>
            <div className="col-md-12 mb-3">
              <label htmlFor="titleTop" className="form-label" style={labelStyle}>Title Top</label>
              <input type="text" className="form-control" id="titleTop" name="titleTop" defaultValue={e.titleTop} style={inputStyle} required />
            </div>
            <div className="col-md-12 mb-3">
              <label htmlFor="titleTwo" className="form-label" style={labelStyle}>Title Two</label>
              <input type="text" className="form-control" id="titleTwo" name="titleTwo" defaultValue={e.titleTwo} style={inputStyle} required />
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="desc" className="form-label" style={labelStyle}>Description</label>
              <textarea className="form-control" id="desc" name="desc" defaultValue={e.desc} style={inputStyle} rows="3" required />
            </div>
            <div className="col-md-12 mb-3">
              <label htmlFor="image" className="form-label" style={labelStyle}>Image URL</label>
              <input type="text" className="form-control" id="image" name="image" defaultValue={e.image} style={inputStyle} required />
            </div>
            <div className="col-md-12 mb-3">
              <label htmlFor="pointTitle" className="form-label" style={labelStyle}>Point Title</label>
              <input type="text" className="form-control" id="pointTitle" name="pointTitle" defaultValue={e.pointTitle} style={inputStyle} required />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="sectionPointOne" className="form-label" style={labelStyle}>Section Point One</label>
              <input type="text" className="form-control" id="sectionPointOne" name="sectionPointOne" defaultValue={e.sectionPointOne} style={inputStyle} required />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="sectionPointTwo" className="form-label" style={labelStyle}>Section Point Two</label>
              <input type="text" className="form-control" id="sectionPointTwo" name="sectionPointTwo" defaultValue={e.sectionPointTwo} style={inputStyle} required />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="sectionPointThree" className="form-label" style={labelStyle}>Section Point Three</label>
              <input type="text" className="form-control" id="sectionPointThree" name="sectionPointThree" defaultValue={e.sectionPointThree} style={inputStyle} required />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="sectionPointFour" className="form-label" style={labelStyle}>Section Point Four</label>
              <input type="text" className="form-control" id="sectionPointFour" name="sectionPointFour" defaultValue={e.sectionPointFour} style={inputStyle} required />
            </div>
            
            <div className="col-md-6 mb-3">
              <label htmlFor="email" className="form-label" style={labelStyle}>Email</label>
              <input type="email" className="form-control" id="email" name="email" defaultValue={e.email} style={inputStyle} required />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="emailDescText" className="form-label" style={labelStyle}>Email Title</label>
              <input type="text" className="form-control" id="emailDescText" name="emailDescText" defaultValue={e.emailDescText} style={inputStyle} required />
            </div>

            <div className="col-12 text-center mt-4">
              <button 
                type="submit" 
                className="btn" 
                style={buttonStyle}
                onMouseOver={(e) => e.target.style.transform = 'scale(1.05)'}
                onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
              >
                Update
              </button>
            </div>
          </div>
        ))}
      </form>
    </div>
  );
};

export default EditRoadMaps;