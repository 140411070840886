import React from "react";
import Features from "../components/SeoHomePage/Features";
import About from "../components/SeoHomePage/About";
import Plans from "../components/SeoHomePage/Plans";
import BestOption from "../components/SeoHomePage/BestOption";
import VideoSection from "../components/SeoHomePage/VideoSection";
import Testimonials from "../components/HomePage/Testimonials";

const Home = () => {
  return (
    <>
      <VideoSection />
      <Features />
      <About />
      <Plans />
      <BestOption />
      <Testimonials />
    </>
  );
};

export default Home;
