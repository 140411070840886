import React, { useEffect, useState } from 'react';

const VideoSection = () => {
    const [road, SetRoad] = useState({});

    useEffect(() => {
        fetch(`https://leadisking-14a2689aac56.herokuapp.com/road/`)
            .then((res) => res.json())
            .then((info) => SetRoad(info[0]));
    }, []);
    return (
        <>
            <section className="starta-about pb-5 my-5">
                <div className="container position-relative" style={{ zIndex: 2 }}>
                    <div className="about-inner pt-4 position-relative">
                        {/* video Block */}
                        <div className="video-block animate-block">
                            <img
                                className="starta-block-img"
                                src={road.bgimage}
                                alt="BG"
                            />

                            <h1 className="starta-h1 starta-h1-2 text-center mx-auto">
                                {road.title}
                            </h1>
                            <ul className="d-flex flex-wrap starta-list list-unstyled position-relative">
                                <li className="me-3">
                                    <i className="fa-solid fa-circle-check" />
                                    {road.pointOne}
                                </li>
                                <li>
                                    <i className="fa-solid fa-circle-check" />
                                    {road.pointTwo}
                                </li>
                            </ul>
                            <button className="starta-button starta-button-2">
                                <span className="position-relative">
                                    {road.btnText}
                                </span>
                            </button>
                        </div>
                        <div className="row align-items-center pt-5">
                            <div className="col-md-7 pt-3">
                                <h5 className="starta-semi">
                                    {road.titleTop}
                                </h5>
                                <h1 className="starta-h1">
                                    {road.titleTwo}
                                </h1>
                                {/* clients  */}
                                <div className="clients">
                                    <img
                                        src={road.image}
                                        class="img-fluid rounded-top"
                                        alt=""
                                    />

                                </div>
                            </div>
                            <div className="col-md-5">
                                <p className="starta-desc mt-sm-4">
                                    {road.desc}
                                </p>
                                <div className="row align-items-center">
                                    <div className="col-md-3 col-sm-2 col-3">
                                        <img
                                            className="check"
                                            src="https://attherate.dev/leadisking/guarantee.png"
                                            alt="Check"
                                        />
                                    </div>
                                    <div className="col-md-9 my-5 col-sm-10 col-9">
                                        <h5 className="starta-h3">
                                            {road.pointTitle}
                                        </h5>

                                    </div>
                                    <ul className="starta-list list-unstyled">
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            {road.sectionPointOne}
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            {road.sectionPointTwo}
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            {road.sectionPointThree}
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-circle-check" />
                                            {road.sectionPointFour}
                                        </li>
                                    </ul>
                                </div>
                                {/* Contact Box */}
                                <div className="contact-box mt-5">
                                    <div className="row align-items-center">
                                        <div className="col-md-2 col-sm-2 col-2">
                                            <i className="fa-solid fa-envelope-open" />
                                        </div>
                                       
                                        <div className="col-md-10 col-sm-10 col-10">
                                            <article>
                                                {road.emailDescText}
                                                <a href={`mailto:${road.email}`}>  {road.email}</a>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* starta about bg */}
                <div className="starta-about-bg">
                    <img src="/assets/images/main/about/aboutBG.png" alt="" />
                </div>
            </section>


        </>
    );
};

export default VideoSection;