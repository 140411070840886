import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";

const DashboardMenu = () => {
  const [user] = useAuthState(auth);

  const handleSignout = () => {
    signOut(auth);
  };

  return (
    <section className="min-vh-100 bg-light">
      <div className="container py-5">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h1 className="text-primary">Dashboard</h1>
          {user && (
            <button className="btn btn-danger btn-sm"
              onClick={handleSignout}>
              Sign Out
            </button>
          )}
        </div>

        <div className="row g-4">
          {[
            {
              icon: "3566511.png",
              title: "Packages (edit)",
              link: "/admin/packages/",
            },
            {
              icon: "1162456.png",
              title: "Total Orders",
              link: "/admin/orders/",
            },
            {
              icon: "3940201.png",
              title: "Setting Option",
              link: "/admin/setting",
            },
            {
              icon: "2571010.png",
              title: "Contact Messages",
              link: "/admin/contact-messages/",
            },
            {
              icon: "1256650.png",
              title: "Manage Users",
              link: "/admin/manage-users/",
            }
          ].map((item, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
              <div className="card shadow-sm h-100">
                <div className="card-body text-center">
                  <img
                    src={`https://firebasestorage.googleapis.com/v0/b/mobile-app-d6c0d.appspot.com/o/images%2F${item.icon}?alt=media`}
                    alt={item.title}
                    width={75}
                    height={75}
                    className="mb-3"
                  />
                  <h5 className="card-title">
                    <Link to={item.link} className="stretched-link text-decoration-none">
                      {item.title}
                    </Link>
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DashboardMenu;
