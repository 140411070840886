import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackToAdminDashboard from "./BackToAdminDashboard";

const EditFeaturePageTwo = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState({});

    useEffect(() => {
        fetch(`https://leadisking-14a2689aac56.herokuapp.com/feature-page-two`)
            .then((res) => res.json())
            .then((info) => setTitle(info[0]));
    }, []);

    const handleTitle = (event) => {
        event.preventDefault();
        const titleTop = event.target.titleTop.value;
        const title = event.target.title.value;
        const desc = event.target.desc.value;
        const image = event.target.image.value;
        const pointOne = event.target.pointOne.value;
        const pointTwo = event.target.pointTwo.value;
        const pointThree = event.target.pointThree.value;
        const btnText = event.target.btnText.value;
        const btnLink = event.target.btnLink.value;
        const reviewCount = event.target.reviewCount.value;
        const reviewTitle = event.target.reviewTitle.value;

        const testimonialTitle = {
            titleTop,
            title,
            desc,
            image,
            pointOne,
            pointTwo,
            pointThree,
            btnText,
            btnLink,
            reviewCount,
            reviewTitle,
        };

        const url = `https://leadisking-14a2689aac56.herokuapp.com/edit-feature-page-two/${id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(testimonialTitle),
        })
            .then((res) => res.json())
            .then((result) => {
                navigate("/admin/setting-homepage/");
            });
    };

    const containerStyle = {
        minHeight: '100vh',
        backgroundColor: '#f8f9fa',
        paddingTop: '2rem',
        paddingBottom: '2rem'
    };

    const cardStyle = {
        backgroundColor: '#ffffff',
        borderRadius: '15px',
        boxShadow: '0 0 20px rgba(0,0,0,0.05)',
        padding: '2rem',
        maxWidth: '800px',
        margin: '0 auto',
        marginTop: '2rem'
    };

    const headerStyle = {
        fontSize: '24px',
        color: '#1a1a1a',
        marginBottom: '1.5rem',
        textAlign: 'center',
        fontWeight: '600'
    };

    const inputGroupStyle = {
        marginBottom: '1.5rem'
    };

    const labelStyle = {
        display: 'block',
        marginBottom: '0.5rem',
        color: '#4a5568',
        fontSize: '0.95rem',
        fontWeight: '500'
    };

    const inputStyle = {
        width: '100%',
        padding: '0.75rem 1rem',
        borderRadius: '10px',
        border: '1px solid #e2e8f0',
        backgroundColor: '#f8fafc',
        fontSize: '1rem',
        transition: 'all 0.3s ease',
        outline: 'none'
    };

    const buttonStyle = {
        background: 'linear-gradient(135deg, #6366F1 0%, #8B5CF6 100%)',
        color: 'white',
        padding: '0.75rem 2rem',
        borderRadius: '30px',
        border: 'none',
        fontSize: '1rem',
        fontWeight: '500',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        boxShadow: '0 4px 6px rgba(99, 102, 241, 0.25)',
        display: 'block',
        margin: '2rem auto 0',
        textAlign: 'center'
    };

    return (
        <div style={containerStyle}>
            <BackToAdminDashboard />

            <div style={cardStyle}>
                <h2 style={headerStyle}>Update Feature Two Option</h2>

                <form onSubmit={handleTitle}>
                    <div style={inputGroupStyle}>
                        <label style={labelStyle}>Top Title</label>
                        <input
                            type="text"
                            style={inputStyle}
                            placeholder="Enter Top Title"
                            name="titleTop"
                            defaultValue={title.titleTop}
                        />
                    </div>

                    <div style={inputGroupStyle}>
                        <label style={labelStyle}>Main Title</label>
                        <input
                            type="text"
                            style={inputStyle}
                            placeholder="Enter Main Title"
                            name="title"
                            defaultValue={title.title}
                        />
                    </div>

                    <div style={inputGroupStyle}>
                        <label style={labelStyle}>Description</label>
                        <textarea
                            style={inputStyle}
                            placeholder="Enter description"
                            name="desc"
                            defaultValue={title.desc}
                            rows="4"
                        />
                    </div>

                    <div style={inputGroupStyle}>
                        <label style={labelStyle}>Image URL</label>
                        <input
                            type="text"
                            style={inputStyle}
                            placeholder="Enter image URL"
                            name="image"
                            defaultValue={title.image}
                        />
                    </div>

                    <div style={inputGroupStyle}>
                        <label style={labelStyle}>Point One</label>
                        <input
                            type="text"
                            style={inputStyle}
                            placeholder="Enter first point"
                            name="pointOne"
                            defaultValue={title.pointOne}
                        />
                    </div>

                    <div style={inputGroupStyle}>
                        <label style={labelStyle}>Point Two</label>
                        <input
                            type="text"
                            style={inputStyle}
                            placeholder="Enter second point"
                            name="pointTwo"
                            defaultValue={title.pointTwo}
                        />
                    </div>

                    <div style={inputGroupStyle}>
                        <label style={labelStyle}>Point Three</label>
                        <input
                            type="text"
                            style={inputStyle}
                            placeholder="Enter third point"
                            name="pointThree"
                            defaultValue={title.pointThree}
                        />
                    </div>

                    <div style={inputGroupStyle}>
                        <label style={labelStyle}>Button Text</label>
                        <input
                            type="text"
                            style={inputStyle}
                            placeholder="Enter button text"
                            name="btnText"
                            defaultValue={title.btnText}
                        />
                    </div>

                    <div style={inputGroupStyle}>
                        <label style={labelStyle}>Button Link</label>
                        <input
                            type="text"
                            style={inputStyle}
                            placeholder="Enter button link"
                            name="btnLink"
                            defaultValue={title.btnLink}
                        />
                    </div>

                    <div style={inputGroupStyle}>
                        <label style={labelStyle}>Review Count</label>
                        <input
                            type="text"
                            style={inputStyle}
                            placeholder="Enter review count"
                            name="reviewCount"
                            defaultValue={title.reviewCount}
                        />
                    </div>

                    <div style={inputGroupStyle}>
                        <label style={labelStyle}>Review Title</label>
                        <input
                            type="text"
                            style={inputStyle}
                            placeholder="Enter review title"
                            name="reviewTitle"
                            defaultValue={title.reviewTitle}
                        />
                    </div>

                    <button
                        type="submit"
                        style={buttonStyle}
                        onMouseOver={(e) => {
                            e.target.style.transform = 'translateY(-2px)';
                            e.target.style.boxShadow = '0 6px 8px rgba(99, 102, 241, 0.35)';
                        }}
                        onMouseOut={(e) => {
                            e.target.style.transform = 'translateY(0)';
                            e.target.style.boxShadow = '0 4px 6px rgba(99, 102, 241, 0.25)';
                        }}
                    >
                        Update Feature
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditFeaturePageTwo;