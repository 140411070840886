import React, { useEffect, useState } from "react";
import "./UserDashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../firebase.init";
import MyAllLeads from "./MyAllLeads";
import { CirclePlusIcon, ListCheck } from "lucide-react";
import { signOut } from "firebase/auth";

const UserDashboard = () => {
  const [profile, setProfile] = useState([]);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [myLeads, setMyLeads] = useState([]);
  const [lists, setLists] = useState([]);

  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/profiles/`)
      .then((res) => res.json())
      .then((info) => setProfile(info));
  }, []);

  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/lists/`)
      .then((res) => res.json())
      .then((info) => setLists(info));
  }, []);

  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/my-all-leads/`)
      .then((res) => res.json())
      .then((info) => {
        const filteredLeads = info.filter(
          (lead) => lead.leadAdded === user?.email
        );
        setMyLeads(filteredLeads);
      });
  }, [user]);
  const handleSignout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Sign-out error:", error);
      });
  };

  return (
    <>
      <section className="min-vh-100 bg-light">
        <div className="container py-5">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="text-primary">Dashboard</h1>
            {user && (
              <button className="btn btn-danger btn-sm"
                onClick={handleSignout}>
                Sign Out
              </button>
            )}
          </div>

          <div className="row g-4">
            {[
              {
                icon: "1162456.png",
                title: "Total Orders",
                link: "/user-dashboard/my-orders/",
              },
              {
                icon: "3940201.png",
                title: "Pending Payments",
                link: "/pending-payment/",
              }
            ].map((item, index) => (
              <div className="col-lg-6 col-md-6" key={index}>
                <div className="card shadow-sm h-100">
                  <div className="card-body text-center">
                    <img
                      src={`https://firebasestorage.googleapis.com/v0/b/mobile-app-d6c0d.appspot.com/o/images%2F${item.icon}?alt=media`}
                      alt={item.title}
                      width={75}
                      height={75}
                      className="mb-3"
                    />
                    <h5 className="card-title">
                      <Link to={item.link} className="stretched-link text-decoration-none">
                        {item.title}
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default UserDashboard;
