import React, { useEffect, useState } from 'react';

const    Features = () => {
    const [features, setFeature] = useState([]);
    const [featuresTwo, setFeaturesTwo] = useState({});

    useEffect(() => {
        fetch(`https://leadisking-14a2689aac56.herokuapp.com/features`)
            .then((res) => res.json())
            .then((info) => setFeature(info));
    }, []);

    useEffect(() => {
        fetch(`https://leadisking-14a2689aac56.herokuapp.com/feature-page-two`)
            .then((res) => res.json())
            .then((info) => setFeaturesTwo(info[0]));
    }, []);

    return (
        <>
            <section className="startaFeatures" id="about">
                <div className="container">
                    <h2 className="starta-h2 text-center">Features Designed for Digital Marketers</h2>
                    <div className="row g-4">
                        {features.map((feature) => (
                            <div key={feature._id} className="col-lg-4 col-md-6">
                                <div
                                    className="feature-card h-100 p-4 rounded-3 bg-white"
                                    style={{
                                        transition: 'all 0.3s ease',
                                        boxShadow: '0 2px 15px rgba(0, 0, 0, 0.05)',
                                        border: '1px solid rgba(0, 0, 0, 0.05)'
                                    }}
                                >
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <div
                                                className="feature-icon rounded-circle p-3"
                                                style={{
                                                    backgroundColor: '#f0f4ff',
                                                    width: '70px',
                                                    height: '70px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <img
                                                    src={feature.featureImg}
                                                    alt={feature.featureTitle}
                                                    style={{
                                                        width: '60px',
                                                        height: '60px',
                                                        objectFit: 'contain'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="feature-content">
                                                <h4
                                                    className="mb-2"
                                                    style={{
                                                        fontSize: '1.1rem',
                                                        fontWeight: '600',
                                                        color: '#2d3436'
                                                    }}
                                                >
                                                    {feature.featureTitle}
                                                </h4>
                                                <p
                                                    className="mb-0"
                                                    style={{
                                                        fontSize: '0.9rem',
                                                        color: '#636e72',
                                                        lineHeight: '1.6'
                                                    }}
                                                >
                                                    {feature.featureDesc}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* gap */}
                    <div className="lineGap my-4" />
                    <div className="row my-5 py-md-4">
                        {/* Starta BLock */}
                        <div className="col-md-6 position-relative">
                            {featuresTwo.image &&
                                <>
                                    <img
                                        className="starta-block-img animate-img"
                                        src={featuresTwo.image}
                                        alt="Block"
                                    />
                                </>}
                        </div>
                        {/* About Content */}
                        <div className="col-md-6 mt-3">
                            <h2 className="starta-heading">
                                <i className="fa-solid fa-star" />
                                {featuresTwo.titleTop}
                            </h2>
                            <h1 className="starta-h1">{featuresTwo.title}</h1>
                            <p className="starta-desc">
                                {featuresTwo.desc}
                            </p>
                        
                            
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
};

export default Features;
