import React, { useEffect, useState } from 'react';

const BestOption = () => {
    const [choose, SetChoose] = useState({});

    useEffect(() => {
        fetch(`https://leadisking-14a2689aac56.herokuapp.com/why-choose/`)
            .then((res) => res.json())
            .then((info) => SetChoose(info[0]));
    }, []);
    return (
        <>
            <section className="starta-details py-5 my-5" id="best">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1 className="starta-heading">{choose.titleTop}</h1>
                            <h1 className="starta-h1">{choose.title}</h1>
                            <p className="starta-desc">
                                {choose.desc}
                            </p>
                            <ul className="list-unstyled details-list">
                                <li>
                                    <img src="assets/images/main/services/details/1.png" alt="Icon" />
                                    {choose.TitleBoxOne}
                                </li>
                                <li>
                                    <img src="assets/images/main/services/details/2.png" alt="Icon" />
                                    {choose.TitleBoxTwo}
                                </li>
                                <li>
                                    <img src="assets/images/main/services/details/3.png" alt="Icon" />
                                    {choose.TitleBoxThree}
                                </li>
                            </ul>

                        </div>
                        <div className="col-md-6 position-relative">
                            <div className="details_img detail_img1">
                                <img
                                    src={choose.imgBoxOne}
                                    alt="Details"
                                />
                            </div>
                            <div className="details_img detail_img2">
                                <img
                                    src={choose.imgBoxThree}
                                    alt="Details"
                                />
                            </div>
                            <div className="details_img detail_img3">
                                <img
                                    src={choose.imgBoxTwo}
                                    alt="Details"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
};

export default BestOption;