import React from "react";
import Features from "../components/SeoHomePage/Features";
import Faqs from "../components/SeoHomePage/Faqs";
import BestOption from "../components/SeoHomePage/BestOption";
import Testionials from "../components/SeoHomePage/Testionials";


const WhyChooseUs = () => {
  return (
    <>
      <BestOption />
      <Features />
      <Faqs />
      <Testionials />

    </>
  );
};

export default WhyChooseUs;