// import React from 'react';
// import auth from '../../firebase.init';
// import { useAuthState } from 'react-firebase-hooks/auth';
// import { Navigate, useLocation } from 'react-router-dom';
// import Loading from './Loading';

// const RequireAuth = ({children}) => {
//     const [user, loading, error] = useAuthState(auth);
//     const location = useLocation();

//     if(loading){
//         return <Loading></Loading>
//     }
//     if(!user){
//         return <Navigate to='/register' state={{from: location}} replace></Navigate>
//     }
//     return children;
// };

// export default RequireAuth;
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useLocation } from "react-router-dom";
import auth from "../../firebase.init";
import Loading from "./Loading";

const RequireAuth = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return (
      <Navigate
        to="/register"
        state={{ from: location }} // Save current route
        replace
      />
    );
  }

  return children;
};

export default RequireAuth;
