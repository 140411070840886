import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const YourDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [packageData, setPackageData] = useState({});

  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/package/${id}`)
      .then((res) => res.json())
      .then((info) => setPackageData(info));
  }, [id]);

  return (
    <>
      <section className="min-vh-100 bg-gradient-light d-flex align-items-center">
        <div className="container py-5">
          <div
            className="card mx-auto shadow-lg border-0 rounded-4"
            style={{ maxWidth: "800px" }}
          >
            <div className="card-header bg-gradient-primary text-center rounded-top">
              <h2 className="card-title fw-bold mb-0">Package Details</h2>
              <p className="small mb-0">Review your selected package</p>
            </div>
            <div className="card-body p-4">
              <h4 className="card-title mb-4 text-gradient">
                Package : {packageData.packageName || "Package Name"}
              </h4>
              <table
                className="table table-bordered table-striped"
                style={{
                  textAlign: "left",
                  border: "1px solid #dee2e6",
                  fontSize: "1rem",
                }}
              >
                <thead className="thead-light">
                  <tr>
                    <th style={{ width: "50%", backgroundColor: "#f8f9fa" }}>
                      Feature
                    </th>
                    <th style={{ width: "50%" }}>Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>Price</strong>
                    </td>
                    <td>${packageData.price || "0.00"}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Access to</strong>
                    </td>
                    <td>{packageData.pointOne || "-"}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>100% Valid Emails</strong>
                    </td>
                    <td>{packageData.pointTwo || "-"}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Instant Access</strong>
                    </td>
                    <td>{packageData.pointFive || "-"}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Fast Support</strong>
                    </td>
                    <td>{packageData.pointSix || "-"}</td>
                  </tr>
                </tbody>
              </table>
              <div className="text-center">
                <button
                  class="starta-button"
                  onClick={() => navigate(`/package/${packageData._id}`)}
                >
                  <span class="position-relative">Continue</span>
                  <span class="starta-button-hover"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default YourDetails;
