import React from 'react';
import { Link } from 'react-router-dom';

const CustomLink = ({
    to,
    children,
    variant = 'primary',
    size = 'md',
    className = '',
    ...props
}) => {
    // Predefined color variants
    const variants = {
        primary: {
            backgroundColor: '#2CC392',
            hoverColor: '#25A67D',
            focusRingColor: 'rgba(44, 195, 146, 0.5)',
        },
        secondary: {
            backgroundColor: '#6B7280',
            hoverColor: '#4B5563',
            focusRingColor: 'rgba(107, 114, 128, 0.5)',
        },
        danger: {
            backgroundColor: '#EF4444',
            hoverColor: '#DC2626',
            focusRingColor: 'rgba(239, 68, 68, 0.5)',
        },
        warning: {
            backgroundColor: '#F59E0B',
            hoverColor: '#D97706',
            focusRingColor: 'rgba(245, 158, 11, 0.5)',
        },
    };

    // Size variations
    const sizes = {
        sm: {
            padding: '0.375rem 0.75rem',
            fontSize: '0.75rem',
        },
        md: {
            padding: '0.5rem 1rem',
            fontSize: '0.875rem',
        },
        lg: {
            padding: '0.75rem 1.5rem',
            fontSize: '1rem',
        },
    };

    const baseStyle = {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        color: 'white',
        borderRadius: '0.375rem',
        cursor: 'pointer',
        transition: 'all 150ms ease-in-out',
        textDecoration: 'none',
        border: 'none',
        outline: 'none',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        ...sizes[size],
    };

    const [currentStyle, setCurrentStyle] = React.useState({
        ...baseStyle,
        backgroundColor: variants[variant].backgroundColor,
    });

    const handleMouseOver = () => {
        setCurrentStyle(prev => ({
            ...prev,
            backgroundColor: variants[variant].hoverColor,
        }));
    };

    const handleMouseOut = () => {
        setCurrentStyle(prev => ({
            ...prev,
            backgroundColor: variants[variant].backgroundColor,
        }));
    };

    const handleFocus = () => {
        setCurrentStyle(prev => ({
            ...prev,
            outline: '2px solid transparent',
            outlineOffset: '2px',
            boxShadow: `0 0 0 2px ${variants[variant].focusRingColor}`,
        }));
    };

    const handleBlur = () => {
        setCurrentStyle(prev => ({
            ...prev,
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        }));
    };

    return (
        <Link
            to={to}
            style={currentStyle}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={className}
            {...props}
        >
            {children}
        </Link>
    );
};

export default CustomLink;


// // Default primary button (medium size)
// <CustomLink to="/login">Login</CustomLink>

// // Small danger button
// <CustomLink to="/delete" variant="danger" size="sm">Delete</CustomLink>

// // Large secondary button
// <CustomLink to="/cancel" variant="secondary" size="lg">Cancel</CustomLink>

// // With custom className
// <CustomLink to="/submit" className="mt-4">Submit</CustomLink>