import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const PayNow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First fetch PayPal client ID
        const paypalResponse = await fetch('https://leadisking-14a2689aac56.herokuapp.com/payments');
        if (!paypalResponse.ok) {
          throw new Error('Failed to fetch PayPal configuration');
        }
        const paypalData = await paypalResponse.json();
        if (paypalData && paypalData[0]) {
          setClientId(paypalData[0].email);
        }

        // Then fetch order details
        const response = await fetch(`https://leadisking-14a2689aac56.herokuapp.com/order/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch order details');
        }
        const data = await response.json();
        
        // Validate the required fields
        if (!data.packagePrice || isNaN(parseFloat(data.packagePrice))) {
          throw new Error('Invalid package price');
        }
        
        setOrder(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const updatePaymentStatus = async (orderId, transactionId) => {
    try {
      setLoading(true);
      const response = await fetch(`https://leadisking-14a2689aac56.herokuapp.com/payment-received/${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentStatus: 'Received',
          orderStatus: 'Completed',
          transactionId: transactionId
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.text();
        throw new Error(errorData);
      }
      
      navigate(`/user-dashboard/my-orders/`);
    } catch (error) {
      setError(`Error updating payment status: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    );
  }

  if (!order || !clientId) {
    return (
      <div className="container mt-5">
        <div className="alert alert-warning" role="alert">
          {!order ? "Order not found" : "PayPal configuration not available"}
        </div>
      </div>
    );
  }

  return (
    <section className="banner">
      <div className="container">
        <div className="row vh-100 align-items-center">
          <div className="col-md-8 mx-auto text-center">
            <div className="card p-4 shadow-sm border-0" style={{ backgroundColor: 'rgb(250, 249, 245)' }}>
              <h6 className="text-muted mb-4">Pay Now</h6>
              <h2 className="display-4 mb-4">
                {order.packageName}
              </h2>
              <div className="mt-4 mb-4">
                <h3 className="text-muted">
                  Amount to Pay: ${parseFloat(order.packagePrice).toFixed(2)} USD
                </h3>
              </div>
              
              <PayPalScriptProvider options={{ "client-id": clientId }}>
                <PayPalButtons
                  createOrder={(data, actions) => {
                    // Ensure price is valid and properly formatted
                    const price = parseFloat(order.packagePrice).toFixed(2);
                    if (isNaN(price) || price <= 0) {
                      throw new Error('Invalid price');
                    }

                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            currency_code: "USD",
                            value: price
                          },
                          description: `${order.packageName} - Order ID: ${order.orderId}`,
                          custom_id: order._id
                        }
                      ]
                    });
                  }}
                  onApprove={async (data, actions) => {
                    try {
                      const details = await actions.order.capture();
                      if (details.status === "COMPLETED") {
                        await updatePaymentStatus(
                          order._id, 
                          details.purchase_units[0].payments.captures[0].id
                        );
                      }
                    } catch (error) {
                      setError(`Payment processing error: ${error.message}`);
                    }
                  }}
                  onError={(err) => {
                    setError(`PayPal Error: ${err.message}`);
                    console.error('PayPal Error Details:', err);
                  }}
                  style={{
                    layout: 'vertical',
                    color: 'blue',
                    shape: 'rect',
                    label: 'pay'
                  }}
                />
              </PayPalScriptProvider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PayNow;