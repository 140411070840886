import React from 'react';
import Plans from '../SeoHomePage/Plans';

const Pricing = () => {
  return (
    <>
      <Plans />

    </>
  );
};

export default Pricing;