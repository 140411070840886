import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import auth from "../../firebase.init";
import BackToAdminDashboard from "./BackToAdminDashboard";

const PackageTitleEdit = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState([]);
  const [user] = useAuthState(auth);
  const { id } = useParams();

  useEffect(() => {
    fetch(`https://leadisking-14a2689aac56.herokuapp.com/package-title/${id}`)
      .then((res) => res.json())
      .then((info) => setTitle(info));
  }, [id]);

  const handlePackagesTitle = (event) => {
    event.preventDefault();
    const packageTitle = {
      titleOne: event.target.titleOne.value,
      description: event.target.description.value,
    };

    fetch(`https://leadisking-14a2689aac56.herokuapp.com/package-title/${id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(packageTitle),
    })
      .then((res) => res.json())
      .then(() => {
        navigate("/admin/dashboard/");
      });
  };

  const renderFormInput = (label, name, defaultValue, type = "text") => (
    <div className="col-12 mb-4">
      <div className="form-group">
        <label className="form-label fw-semibold text-secondary mb-2">
          {label}
        </label>
        <input
          type={type}
          className="form-control form-control-lg shadow-sm border-0"
          placeholder={`Enter ${label}`}
          name={name}
          defaultValue={defaultValue}
          style={{
            backgroundColor: "#f8f9fa",
            borderRadius: "10px",
            padding: "12px 15px"
          }}
        />
      </div>
    </div>
  );

  return (
    <div className="py-5" style={{ backgroundColor: "#ffffff", minHeight: "100vh" }}>
      <BackToAdminDashboard />
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card border-0 shadow-lg" style={{ borderRadius: "15px" }}>
              <div className="card-body p-5">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h2 className="card-title mb-0" style={{ color: "#2c3e50", fontWeight: "600" }}>
                    Edit Package Title
                  </h2>
                  
                </div>
                
                <form onSubmit={handlePackagesTitle}>
                  <div className="row">
                    {renderFormInput("Title", "titleOne", title.titleOne)}
                    {renderFormInput("Description", "description", title.description)}
                    
                    <div className="col-12 mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg px-5 py-3"
                        style={{
                          backgroundColor: "#3498db",
                          border: "none",
                          borderRadius: "10px",
                          boxShadow: "0 4px 6px rgba(52, 152, 219, 0.2)",
                          transition: "all 0.3s ease"
                        }}
                      >
                        Update Title
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageTitleEdit;