import React from 'react';
import { Star } from 'lucide-react';

const Testimonials = () => {
  const testimonials = [
    {
      "_id": "653b1eff64f1659d39a517c0",
      "personName": "Maria Prokash",
      "personTitle": "Managing Director",
      "personImg": "/api/placeholder/80/80",
      "desc": "Huntcrow.com has revolutionized the way I generate leads for my business. The tools are intuitive, efficient, and have immensely increased the quality of leads. I highly recommend ProLeads.shop to anyone looking for a reliable and effective lead generation solution"
    },
    {
      "_id": "653b2272b0b7ef030f62c7d5",
      "personName": "Spark Lee",
      "personTitle": "Digital Marketer",
      "personImg": "/api/placeholder/80/80",
      "desc": "HuntCrow.com has exceeded my expectations! The user-friendly interface, coupled with powerful lead generation tools, has proven instrumental in elevating my product promotion."
    },
    {
      "_id": "67592df5a6929fecf7f2f293",
      "personName": "David D. Houchins",
      "personTitle": "Owner",
      "personImg": "/api/placeholder/80/80",
      "desc": "ProLeads.shop is a game-changer for small businesses. As a startup, I needed a solution that was both cost-effective and powerful."
    }
  ];

  return (
    <section className="bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial) => (
            <div 
              key={testimonial._id}
              className="bg-white rounded-lg shadow-md p-6 relative"
            >
              <div className="flex items-start mb-4">
                <img
                  src={testimonial.personImg}
                  alt={testimonial.personName}
                  className="w-16 h-16 rounded-full mr-4"
                />
                <div>
                  <h3 className="font-semibold text-lg">{testimonial.personName}</h3>
                  <p className="text-gray-600">{testimonial.personTitle}</p>
                  <div className="flex mt-1">
                    {[...Array(5)].map((_, i) => (
                      <Star 
                        key={i}
                        size={16}
                        className="text-yellow-400 fill-current"
                      />
                    ))}
                  </div>
                </div>
              </div>
              <p className="text-gray-700 leading-relaxed">{testimonial.desc}</p>
              <div className="absolute top-4 right-4">
                <svg
                  className="w-8 h-8 text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
                </svg>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;